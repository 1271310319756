<template>
    <div class="wpg">
        <router-view/>
        <Nav />
    </div>
</template>

<script>
import Nav from '@/components/nav.vue'
export default {
    components:{
        Nav:Nav
    },
    data(){
        return{}
    }
}
</script>

<style lang="scss">
.van-button--primary {
    color: #fff;
    background-color: #2c6a8e;
    border: 1px solid #2c6a8e;
}
.glo-content{padding: 4%;}
 .glo-magbot{margin-bottom: 24%;}
 .glo-relative{
    position: relative;
 }
</style>>
